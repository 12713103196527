<template>
  <div>
    <!-- Components -->
    <darkBackground />
    <saveResultsMutation />
    <results :max="questions.length"/>
    <overview :currentQuestionId=currentQuestionId :reviewMode="examMode === 'review'" @changeQuestion="changeQuestion" @buttonClicked="finishExam" />
    <infoOverlay text="Möchtest du die Prüfung wirklich abgeben?" button="Prüfung abgeben" @buttonClicked="finishExam" index=1 />
    <infoOverlay text="Bist du sicher, dass du alle Fragen beantwortet hast?" button="Zur Auswertung" index=2 @buttonClicked="finishExam" />
    <infoOverlay text="Deine Zeit ist abgelaufen" button="Zum Ergebnis" index=3 @buttonClicked="finishExam" denyClosing=true />
    <infoOverlay text="Du hast noch nicht alle Antworten ausgewählt." index=4 />
    <!-- Components -->


    <!-- Nav Bar Overlay-->
    <div class="w-full md:w-24 md:h-screen bg-new-yellow md:bg-transparent fixed flex flex-row md:flex-col justify-between md:justify-between items-center z-10 top-0 md:left-0 p-5 font-arial text-new-black border-b-2 md:border-none  border-new-yellow" :class="{'pt-safe' : parseInt(sat) !== 0, 'transition duration-300 ease-in-out border-hi-lighgrey' : scrollDown}">
      <div class="relative flex flex-row justify-between items-center w-full md:order-last">
        <div class="z-10 cursor-pointer" @click="cancelTraining" >
          <backSVG color='red' class="md:hidden flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
          <backBoldSVG color='red' class="hidden md:block flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
        </div>
        <div v-if="examMode === 'exam'" class="md:hidden flex flex-row justify-center items-center w-full text-lg">Verbleibende Zeit {{formatTime(remainingTimeInSeconds)}}</div>
      </div>
      <gridSVG @click="openOverview" class="flex-shrink-0 md:order-first md:p-2 md:right-0 md:w-12 md:h-12 md:mt-5 cursor-pointer"/>
    </div>
    <!-- Nav Bar Overlay-->


    <!-- Content -->
    <div class="w-full min-h-screen md:max-h-screen md:overflow-hidden p-5 md:p-0 mt-5 md:mt-0 md:pl-24 bg-new-yellow font-arial text-new-black select-none mb-36 md:mb-0 md:flex md:flex-row" :class="{'pt-safe' : parseInt(sat) !== 0}">

      <!-- Desktop Left Side -->
      <div class="md:w-1/2 md:px-10 py-5 md:flex md:flex-col md:h-screen">

        <!-- Heading -->
        <div class="mt-6 font-faro font-bold text-3xl">Prüfung {{ currentExam?.attributes?.Index }}</div>
        <div v-if="examMode === 'exam'" class="hidden md:block text-xl">Verbleibende Zeit {{formatTime(remainingTimeInSeconds)}}</div>
        <div class="text-xl">Frage {{currentQuestion + 1}} von {{questions?.length}}</div>


        <!-- Scrollable -->
        <div class="md:flex-grow md:overflow-y-scroll scrollbar" >

          <!-- Questions -->
          <tipTap :content="questions[currentQuestion]?.attributes?.Frage" />

          <!-- MC Answers -->
          <div v-if="shouldBeSelectedAnswers === 1" class="mt-5 mb-1 text-xs font-bold">Wähle {{shouldBeSelectedAnswers}} Antwort</div>
          <div v-else class="mt-5 mb-1 text-xs font-bold">Wähle {{shouldBeSelectedAnswers}} Antworten</div>

          <div v-if="questions[currentQuestion]?.attributes?.Antwort" class="w-full bg-white rounded-xl shadow-new border border-hi-lighgrey overflow-hidden">
            <div v-for="(antwort, index) in questions[currentQuestion]?.attributes?.Antwort" :key="antwort" class="w-full p-3 flex flex-row justify-between items-center font-arial cursor-pointer" @click="addAnswer(antwort?.id)"
            :class="{
              'border-b-2 border-new-yellow' : index < questions[currentQuestion]?.attributes?.Antwort?.length -1 ? true : false,
              'bg-new-black' : currentProgress?.selectedAnswersIds?.includes(antwort.id) && examMode === 'exam' ,
              'bg-red-600' : currentProgress?.selectedAnswersIds?.includes(antwort.id) && examMode === 'review' && !currentProgress.correctAnswersIds.includes(Number(antwort.id)),
              'bg-green-500' : currentProgress?.selectedAnswersIds?.includes(antwort.id) && examMode === 'review'   && currentProgress.correctAnswersIds.includes(Number(antwort.id)),
              'bg-green-200' : !currentProgress?.selectedAnswersIds?.includes(antwort.id) && examMode === 'review'  && currentProgress.correctAnswersIds.includes(Number(antwort.id)),
            }">
            <tipTap :content="antwort?.Antwort" displayType="clean" :color="currentProgress.selectedAnswersIds.includes(antwort.id) ? 'white' : 'black'"/>
            </div>
          </div>

        </div>

      </div>


      <!-- Desktop Right Side -->
      <div class="md:w-1/2 md:bg-hi-white md:px-10 md:pt-28 md:h-screen">

        <!-- Comment -->
        <div v-if="questions[currentQuestion]?.attributes?.Kommentar" class="md:h-full">
          <div v-if="showAnswers || examMode === 'review'" class="md:h-full">
            <div id="comment" class="mt-5 mb-1 text-xs font-bold">Kommentar zur Antwort</div>
              <tipTap :content="questions[currentQuestion]?.attributes?.Kommentar" />
          </div>
        </div>

      </div>

    </div>
    <!-- Content -->

    <!-- Buttons -->
    <div class="fixed bottom-0 w-full md:pl-24 md:flex md:flex-row pointer-events-none">
      <div class="hidden md:block md:w-1/2 pointer-events-none"></div>
      <div class="pb-5 min-h-28 w-full md:w-1/2 p-5 md:px-10 bg-white shadow-top md:shadow-none md:bg-transparent pointer-events-auto">
      <div v-if="examMode === 'exam'" class="flex flex-row justify-center items-center">
        <customButton v-if="examMode && currentQuestion !== 0" label='Zurück' :inactive=false @click="previous" class="min-w-1/2 mr-1"/>
        <customButton v-if="examMode && questions.length !== currentQuestion + 1" label='Weiter' @click="next" :inactive=false class="min-w-1/2" :class="{'ml-1' : currentQuestion !== 0}"/>
        <customButton v-if="examMode && questions.length === currentQuestion + 1" label='Auswerten' @click="checkExam" :inactive=false class="min-w-1/2 ml-1"/>
      </div>
      <div v-if="examMode === 'review'" class="flex flex-row justify-center items-center">
        <customButton v-if="currentQuestion !== 0" label='Zurück' @click="previous" :inactive=false class="min-w-1/2 mr-1"/>
        <customButton v-if="questions.length > currentQuestion + 1" label='Weiter' @click="next" :inactive=false class="min-w-1/2" :class="{'ml-1' : currentQuestion !== 0}"/>
        <customButton v-if="questions.length === currentQuestion + 1" label='Beenden' @click="endReview" :inactive=false class="min-w-1/2" :class="{'ml-1' : currentQuestion !== 0}"/>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

// SVG
import backSVG from '../../../assets/svg/back.vue';
import backBoldSVG from '../../../assets/svg/backBold.vue';
import gridSVG from '../../../assets/svg/grid.vue';

// Mixins
import detectNotch from '../../../mixins/detectNotch.vue';
import scrollTo from '../../../mixins/scrollTo.vue';
import fadeInOut from '../../../mixins/animations/fadeInOut.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import customButton from '../../../symbols/customButton.vue';
import darkBackground from '../../../symbols/darkBackground.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';
import tipTap from '../../../symbols/tipTap.vue';
import overview from '../components/overview.vue';
import results from '../components/results.vue';

// Strapi
import saveResultsMutation from '../strapi/saveResultsMutation.vue';

export default {
  name: 'currentExam',
  mixins: [detectNotch, fadeInOut, iosVibrations, scrollTo],
  components: {
    customButton,
    infoOverlay,
    darkBackground,
    tipTap,
    backSVG,
    backBoldSVG,
    gridSVG,
    overview,
    results,
    saveResultsMutation,
  },

  data() {
    return {
      countdownInitialised: false,
      startTime: null,
      serverTime: null,
      currentQuestion: 0,
      endTime: null,
      remainingTimeInSeconds: 0,
      break: false,
      isChecking: false,
    };
  },

  computed: {
    scrollDown() {
      return this.$store.state?.layout?.scrollDown;
    },
    questions() {
      return this.$store.state.exam.questions;
    },
    currentQuestionId() {
      return this?.questions[this?.currentQuestion]?.id;
    },
    validateAnswers() {
      return this.$store?.state?.validateAnswers;
    },
    currentExam() {
      return this.$store.state.exam.exam;
    },
    examMode() {
      return this.$store.state.exam.mode;
    },
    shouldBeSelectedAnswers() {
      return this.questions[this.currentQuestion]?.attributes?.sumCorrect;
    },
    totalProgres() {
      return this.$store.state.exam.currentProgress;
    },
    myExamProgress() {
      return this.me.userData.data.attributes.examProgress;
    },
    currentProgress() {
      return this.$store.getters.currentExamProgress(this?.currentQuestionId);
    },
    currentlySelectedAnswers() {
      return this.$store.getters.currentlySelectedExamAnswers(this?.currentQuestionId);
    },
    showAnswers() {
      if (this.currentProgress?.result !== null) {
        return true;
      }
      return false;
    },
    saveResults() {
      return this.$store.state.saveResults;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    me() {
      return this.$store.state.profile.me;
    },
    finished() {
      return this.$store.state?.exam?.finished;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    totalExamTime() {
      let timePerQuestion = 1.5;
      if (this.currentExam.attributes?.timePerQuestion) {
        timePerQuestion = this.currentExam.attributes.timePerQuestion;
      }
      return this.currentExam.attributes?.mc_fragens?.data?.length * timePerQuestion;
    },
    queryServerTimeEx() {
      return this.$store.state.queryServerTimeEx;
    },
  },

  watch: {
    myExamProgress() {
      const examIndex = this.myExamProgress.findIndex((exam) => exam.pruefungen.data.id === this.currentExam.id);
      if (examIndex !== -1) {
        this.startTime = this.myExamProgress[examIndex].started;
        this.initCountdown();
      }
    },
  },

  beforeMount() {
    this.$store.commit('showSecondOverlay', null);
    this.$store.commit('showOverlay', null);
  },

  beforeUnmount() {
    this.break = true;
  },

  mounted() {
    this.scrollToTop();
    this.saveProgress();
    if (this.examMode === 'exam') {
      this.queryServerTimeEx()
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            console.log('got server time');
            this.serverTime = result.data.getServerTime.serverTime;
            this.initCountdown();
          }
        });
    }
  },

  methods: {

    initCountdown() {
      if (!this.countdownInitialised && this.serverTime && this.startTime && this.totalExamTime) {
        console.log(`serverTime -> ${this.serverTime}`);
        console.log(`started serverTime -> ${this.startTime}`);
        console.log(`totalExamTime -> ${this.totalExamTime}`);
        console.log(`timeDifference MS -> ${this.getServerTimeDifference()}`);
        this.endTime = Date.parse(this.startTime) + this.totalExamTime * 60000 - this.getServerTimeDifference();
        this.remainingTimeInSeconds = this.totalExamTime * 60;
        this.countdownInitialised = true;
        this.startCountDown();
      }
    },

    startCountDown() {
      if (this.remainingTimeInSeconds > 0 && !this.break) {
        const elapsed = new Date() / 1000;
        const totalSec = parseInt(this.endTime / 1000 - elapsed, 10);
        this.remainingTimeInSeconds = totalSec;
        this.timeout = setTimeout(this.startCountDown, 1000);
        console.log('still running');
      } else if (!this.break) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 3 });
      }
    },

    getServerTimeDifference() {
      const now = new Date();
      const serverNow = Date.parse(this.serverTime);
      const difference = now - serverNow;
      return difference;
    },

    addAnswer(answerId) {
      if (this.examMode !== 'review') {
        if (this.currentlySelectedAnswers < this.shouldBeSelectedAnswers || this.currentProgress?.selectedAnswersIds?.includes(answerId)) {
          this.$store.commit('setExamAnswer', { answerId, questionId: this.currentQuestionId });
        }
      }
    },

    finishExam() {
      this.questions.forEach((question) => {
        this.checkQuestion(question.id);
      });
      setTimeout(() => {
        this.break = true;
        this.$store.commit('finishExam');
        this.$store.commit('showOverlay', 'results');
      }, 100);
    },

    timeIsUp() {
      this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      this.$store.commit('finishExam');
      this.iosVibrate(1);
      this.scrollToTop();
      this.$store.commit('showOverlay', 'results');
    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds - (minutes * 60);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (remainingSeconds < 10) {
        remainingSeconds = `0${remainingSeconds}`;
      }
      const timer = `${minutes}:${remainingSeconds}`;
      return timer;
    },

    cancelTraining() {
      if (!this.finished && this.examMode !== 'review') this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
      else {
        this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
        this.$router.push({ name: 'exam' });
      }
    },

    confirmCancelTraining() {
      // this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      /* if (this.examMode === 'exam') this.saveProgress();
      this.$router.push({ name: 'exam' }); */
    },

    endReview() {
      this.$router.push({ name: 'exam' });
    },

    saveProgress() {
      this.queryMeEx().then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          const examProgressToSave = [];
          this.myExamProgress.forEach((examProgress) => {
            examProgressToSave.push({
              id: examProgress.id,
              finished: examProgress.finished,
              passed: examProgress.passed,
              started: examProgress.started,
              progress: examProgress.progress,
              pruefungen: examProgress.pruefungen.data.id,
            });
          });
          const examIndex = examProgressToSave.findIndex((exam) => exam.pruefungen === this.currentExam.id);
          const myExam = examProgressToSave[examIndex];
          myExam.progress = this.totalProgres;
          this.saveResults({ userDataId: this.userDataId, examProgress: examProgressToSave });
        }
      });
    },

    checkQuestion(questionId) {
      this.isChecking = true;
      this.validateAnswers({ questionId }).then((result) => {
        const correctAnswers = result.data.validateAnswers.correctIds;
        const progressIndex = this.totalProgres.data.findIndex((progress) => progress.questionId === questionId);
        const selectedAnswers = this.totalProgres.data[progressIndex].selectedAnswersIds;
        if (correctAnswers.sort().toString() === selectedAnswers.sort().toString()) {
          this.$store.commit('setExamQuestionResult', { result: 'correct', questionId, correctAnswersIds: correctAnswers });
          this.$store.commit('totalExamCorrect');
          this.isChecking = false;
        } else {
          this.$store.commit('setExamQuestionResult', { result: 'wrong', questionId, correctAnswersIds: correctAnswers });
          this.isChecking = false;
        }
      });
    },

    checkExam() {
      this.$store.commit('toggleInfoOverlay', { value: true, index: 2 });
    },

    changeQuestion(index) {
      this.currentQuestion = index;
      this.$store.commit('showOverlay', null);
    },

    next() {
      if (this.examMode && this.currentlySelectedAnswers < this.shouldBeSelectedAnswers && this.currentlySelectedAnswers !== 0 && !this.reviewMode) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
      } else {
        if (this.currentQuestion + 1 === this.questions.length) {
          this.iosVibrate(0);
          this.$store.commit('showOverlay', 'results');
          this.$store.commit('finishExam');
        } else {
          this.currentQuestion += 1;
        }
        this.scrollToTop();
        this.iosVibrate(1);
      }
    },

    previous() {
      if (this.currentQuestion !== 0) {
        this.currentQuestion -= 1;
        this.scrollToTop();
        this.iosVibrate(1);
      }
    },

    openOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'overview');
    },
  },
};
</script>
